import './home.scss';
import '../../assets/styles/main.scss';
import '../../assets/styles/essence.scss';
import '../../assets/styles/about.scss';
import '../../assets/styles/process.scss';
import '../../assets/styles/products.scss';
import '../../assets/styles/work.scss';
import '../../assets/styles/contact.scss';
import '../../assets/styles/video.scss';

import '../../assets/photo-swipe/photoswipe.css';
import '../../assets/photo-swipe/default-skin/default-skin.css';

const $contactForm = $('form#contact-form'), url = 'https://api.cloudsitedsolutions.com/graphql/';
$contactForm.validate({
  errorPlacement: function(error, element) {},
});

const $curriculumForm = $('form#curriculum-form');

$curriculumForm.validate({
  errorPlacement: function(error, element) {},
});


import categories from '../../assets/js/categories';

function getFormData($form) {
  const unIndexed_array = $form.serializeArray();
  const indexed_array = {};

  $.map(unIndexed_array, function(n, i){
    indexed_array[n['name']] = n['value'];
  });

  return indexed_array;
}

$('#fileUploader').click(function (e) {
  e.preventDefault();
  $('#fileUidInput').click();
});

function setHeader(xhr) {
  xhr.setRequestHeader('Access-Control-Allow-Origin', "*");
  xhr.setRequestHeader('Access-Control-Allow-Methods', "POST");
  xhr.setRequestHeader('Access-Control-Allow-Headers', "Content-Type");
  xhr.setRequestHeader('Cache-Control', "no-cache, no-store, must-revalidate, post-check=0, pre-check=0");
  xhr.setRequestHeader('Expires', "0");
}

$('#fileUidInput').change(function (value) {
  value.preventDefault();
  const file = value.target.files[0];
  const formData = new FormData();
  formData.append("image_file", file);
  $('#file-name').html('<span class="avenir">Subiendo archivo...</span>');
  $('#file-name').css('display','inline');
  $('#fileUploader').css('display','none');
  let data = `mutation{
      uploadImg(
         folderName: "form_files") {
         file {
          uid
          url
         }
        }
      }`;
  var xhr;

  xhr = new XMLHttpRequest();

  xhr.open( 'POST', url + '?query=' + data, true );
  xhr.onreadystatechange =  () => {
    if (xhr.readyState === 4) {
      const res = JSON.parse(xhr.response);
      if (res && res.data && res.data.uploadImg && res.data.uploadImg.file) {
        const file = res.data.uploadImg.file;
        $('#file-name').html('<span class="avenir">' + file.url.split('/').slice(-1).pop() + '</span>');
        $('#fileUid').val(file.uid);
      } else {
        alert('Hubo un problema al subir el archivo, intentelo de nuevo.')
        $('#file-name').css('display','none');
        $('#fileUploader').css('display','flex');
      }
    }
  };
  xhr.send( formData );

  // $.ajax({
  //   url: url + '?query=' + data,
  //   method: "POST",
  //   processData: false,
  //   crossDomain: true,
  //   contentType: false,
  //   dataType: 'jsonp',
  //   cache: false,
  //   data: formData,
  //   success: function (res) {
  //     // $(`#contact-thanks`).appendTo('body').modal({
  //     //   fadeDuration: 250
  //     // });
  //     console.log(res);
  //   },
  //   error: function (error) {
  //     console.log(error);
  //   }
  // })
})

$('#submit-curriculum-form').click(function(e) {
  e.preventDefault();
  if ($curriculumForm.valid() && getFormData($curriculumForm).fileUid) {
    $("#submit-curriculum-form").text('Cargando...');
    const formData = getFormData($curriculumForm);

    let data = `mutation{
      sendForm(
         subject: "Formulario de curriculum"
         email: "` + formData.email + `"
         name: "` + formData.name + `"
         phone: "` + formData.phone + `"
         fileUid: "` + formData.fileUid + `"
         ) {
         message
        }
      }`;
    $.ajax({
      url: url + '?query=' + data,
      method: "POST",
      dataType: "json",
      success: function (res) {
        // $(`#contact-thanks`).appendTo('body').modal({
        //   fadeDuration: 250
        // });
        $curriculumForm[0].reset();
        $("#submit-curriculum-form").text('Enviar');
        $('#file-name').css('display','none');
        $('#fileUploader').css('display','flex');
      }
    })
  } else {
    var $inputs = $('#curriculum-form :input');
    $inputs.each(function() {
      var input = $(this);
      if (!input.valid()) {
        input.addClass('error');
      } else {
        input.removeClass('error');
      }
    });
  }
});

$('#submit-contact-form').click(function(e) {
  e.preventDefault();
  if ($contactForm.valid()) {
    $("#submit-contact-form").text('Cargando...');
    const formData = getFormData($contactForm);

    let data = `mutation{
      sendForm(
         subject: "Formulario de contacto"
         email: "` + formData.email + `"
         name: "` + formData.name + `"
         message: "` + formData.message + `"
         ) {
         message
        }
      }`
    $.ajax({
      url: url + '?query=' + data,
      method: "POST",
      dataType: "json",
      success: function (res) {
        // $(`#contact-thanks`).appendTo('body').modal({
        //   fadeDuration: 250
        // });
        $contactForm[0].reset();
        $("#submit-contact-form").text('Enviar');
      }
    })
  } else {
    var $inputs = $('#contact-form :input');
    $inputs.each(function() {
      var input = $(this);
      if (!input.valid()) {
        input.addClass('error');
      } else {
        input.removeClass('error');
      }
    });
  }
});

$('#play-btn').click(function(e) {
  e.preventDefault();
  $('.video-container').removeClass('hide');
  const video = document.getElementById("video-source");
  video.play();
});

$('#close-video').click(function(e) {
  e.preventDefault();
  $('.video-container').addClass('hide');
  const video = document.getElementById("video-source");
  video.pause();
});

$(document).ready(function() {


  categories.forEach(category => {
    $('#products-row').append(
        `
        <a href="./category.html?code=` + category.code + `" class="product">
          <div class="img-box">
            <img alt="` + category.name + `" src="` + category.image + `">
          </div>
          <span class="allerta">` + category.name + `</span>
        </a>
        `
    )
  });

  require([
    '../../assets/photo-swipe/photoswipe.js',
    '../../assets/photo-swipe/photoswipe-ui-default.js'
  ], function( PhotoSwipe, PhotoSwipeUI_Default ) {

    var container = [];

    // Loop over gallery items and push it to the array
    $('#gallery').find('figure').each(function() {
      var $link = $(this).find('a'),
          item = {
            src: $link.attr('href'),
            w: $link.data('width'),
            h: $link.data('height'),
            title: $link.data('caption')
          };
      container.push(item);
    });

    // Define click event on gallery item
    $('.gallery-ref').click(function(event) {

      // Prevent location change
      event.preventDefault();

      // Define object and gallery options
      var $pswp = $('.pswp')[0],
          options = {
            index: $(this).parent('figure').index(),
            bgOpacity: 0.85,
            showHideOpacity: true,

          };

      // Initialize photo-swipe
      var gallery = new PhotoSwipe($pswp, PhotoSwipeUI_Default, container, options);
      gallery.init();
    });

  });

});
