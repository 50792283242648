const categories = [
  {
    name: 'Chifones',
    image: '../assets/images/chifones-lin.png',
    code: 'chifones',
    designs: [
      {
        "TEJIDO": "CHIFFONES",
        "CLASIFICACIÓN": "POLIÉSTER ALGODÓN",
        "NUEVOS": "",
        "CVO": 1,
        "DISEÑOS": "SHIFON YOLIS",
        "COMPOSICION": "PES 50%, CO RS 50%",
        "ANCHO": 1.6,
        "GRS": {
          "M2": 263,
          "ML": 420
        },
        "RENDIMIENTO": 2.4,
        imageFolder: 'CHIFFONES',
        imageTag: "chiffones"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 2,
        "DISEÑOS": "D-211",
        "COMPOSICION": "PES 50%, CO RS 50%",
        "ANCHO": 1.6,
        "GRS": {
          "M2": 200,
          "ML": 320
        },
        "RENDIMIENTO": 3.1,
        imageFolder: 'CHIFFONES',
        imageTag: "chiffones"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 3,
        "DISEÑOS": "INTER LUNA",
        "COMPOSICION": "PES 50%, CO RS 50%",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 230,
          "ML": 345
        },
        "RENDIMIENTO": 2.9,
        imageFolder: 'CHIFFONES',
        imageTag: "chiffones"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 4,
        "DISEÑOS": "INTER KITTY",
        "COMPOSICION": "PES 50%, CO RS 50%",
        "ANCHO": 1.6,
        "GRS": {
          "M2": 200,
          "ML": 320
        },
        "RENDIMIENTO": 3.1,
        imageFolder: 'CHIFFONES',
        imageTag: "chiffones"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 5,
        "DISEÑOS": "SONORA",
        "COMPOSICION": "PES 50%, CO RS 50%",
        "ANCHO": 1.8,
        "GRS": {
          "M2": 139,
          "ML": 250
        },
        "RENDIMIENTO": 4,
        imageFolder: 'CHIFFONES',
        imageTag: "chiffones"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 6,
        "DISEÑOS": "DETROIT",
        "COMPOSICION": "PES 50%, CO PDO 50%",
        "ANCHO": 1.65,
        "GRS": {
          "M2": 155,
          "ML": 255
        },
        "RENDIMIENTO": 3.9,
        imageFolder: 'CHIFFONES',
        imageTag: "chiffones"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "ALGODÓN",
        "NUEVOS": "",
        "CVO": 7,
        "DISEÑOS": "ORLANDO",
        "COMPOSICION": "CO OE 100%",
        "ANCHO": 1.65,
        "GRS": {
          "M2": 150,
          "ML": 250
        },
        "RENDIMIENTO": 4,
        imageFolder: 'CHIFFONES',
        imageTag: "chiffones"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 8,
        "DISEÑOS": "MONTERREY",
        "COMPOSICION": "CO PDO 100%",
        "ANCHO": 1.6,
        "GRS": {
          "M2": 160,
          "ML": 255
        },
        "RENDIMIENTO": 3.9,
        imageFolder: 'CHIFFONES',
        imageTag: "chiffones"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 9,
        "DISEÑOS": "ALAMO",
        "COMPOSICION": "CO RS 100%",
        "ANCHO": 1.7,
        "GRS": {
          "M2": 155,
          "ML": 265
        },
        "RENDIMIENTO": 3.7,
        imageFolder: 'CHIFFONES',
        imageTag: "chiffones"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "POLIÉSTER",
        "NUEVOS": "",
        "CVO": 10,
        "DISEÑOS": "POLY SPUN",
        "COMPOSICION": "PES 1.00",
        "ANCHO": 1.6,
        "GRS": {
          "M2": 125,
          "ML": 200
        },
        "RENDIMIENTO": 5,
        imageFolder: 'CHIFFONES',
        imageTag: "chiffones"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 11,
        "DISEÑOS": "QUIRURSATEX",
        "COMPOSICION": "PES 1.00",
        "ANCHO": 1.6,
        "GRS": {
          "M2": 105,
          "ML": 170
        },
        "RENDIMIENTO": 5.8,
        imageFolder: 'CHIFFONES',
        imageTag: "chiffones"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 12,
        "DISEÑOS": "KENIA",
        "COMPOSICION": "PES 1.00",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 180,
          "ML": 270
        },
        "RENDIMIENTO": 3.7,
        imageFolder: 'CHIFFONES',
        imageTag: "chiffones"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "POLIÉSTER VISCOSA",
        "NUEVOS": "",
        "CVO": 13,
        "DISEÑOS": "BLIZZARD",
        "COMPOSICION": "PES 50%, CV 50%",
        "ANCHO": 1.8,
        "GRS": {
          "M2": 150,
          "ML": 270
        },
        "RENDIMIENTO": 3.7,
        imageFolder: 'CHIFFONES',
        imageTag: "chiffones"
      }
    ]
  },
  {
    name: 'Licras',
    image: '../assets/images/licras-lin.png',
    code: 'licras',
    designs: [
      {
        "TEJIDO": "LICRAS",
        "CLASIFICACIÓN": "ALGODÓN CARDADO",
        "NUEVOS": "",
        "CVO": 14,
        "DISEÑOS": "BAMBI",
        "COMPOSICION": "CO CDO 93%, SPX 7%",
        "ANCHO": 1.7,
        "GRS": {
          "M2": 176,
          "ML": 300
        },
        "RENDIMIENTO": 3.3,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "POLIÉSTER",
        "NUEVOS": "",
        "CVO": 15,
        "DISEÑOS": "IBIZA",
        "COMPOSICION": "85% POL CAT 15% ELAS",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 260,
          "ML": 173
        },
        "RENDIMIENTO": 3.9,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 16,
        "DISEÑOS": "GLACIAL C",
        "COMPOSICION": "PES 86%, SPX 14%",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 390,
          "ML": 585
        },
        "RENDIMIENTO": 1.7,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 17,
        "DISEÑOS": "PACIFICO",
        "COMPOSICION": "PES 86%, SPX 14%",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 313,
          "ML": 470
        },
        "RENDIMIENTO": 2.1,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 18,
        "DISEÑOS": "ARTICA",
        "COMPOSICION": "PES 86%, SPX 14%",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 260,
          "ML": 390
        },
        "RENDIMIENTO": 2.6,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 19,
        "DISEÑOS": "TIFANY BRUSH",
        "COMPOSICION": "PES 97%, SPX 3%",
        "ANCHO": 1.55,
        "GRS": {
          "M2": 225,
          "ML": 145
        },
        "RENDIMIENTO": 4.4,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 20,
        "DISEÑOS": "LONDON",
        "COMPOSICION": "PES 83%, SPX 17%",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 173,
          "ML": 260
        },
        "RENDIMIENTO": 3.8,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 21,
        "DISEÑOS": "IMPACTO",
        "COMPOSICION": "PES 0.88",
        "ANCHO": 1.4,
        "GRS": {
          "M2": 350,
          "ML": 490
        },
        "RENDIMIENTO": 2,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 22,
        "DISEÑOS": "BRILLALIN",
        "COMPOSICION": "PES 97%, SPX 3%",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 220,
          "ML": 330
        },
        "RENDIMIENTO": 3,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 23,
        "DISEÑOS": "ANTARTIDA",
        "COMPOSICION": "PES 86%, SPX 14%",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 260,
          "ML": 390
        },
        "RENDIMIENTO": 2.6,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "POLIÉSTER ALGODÓN",
        "NUEVOS": "",
        "CVO": 24,
        "DISEÑOS": "SUPER PONTI",
        "COMPOSICION": "PES 60%, CO OE 32%, SPX 8%",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 320,
          "ML": 480
        },
        "RENDIMIENTO": 2.1,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "ALGODÓN",
        "NUEVOS": "",
        "CVO": 25,
        "DISEÑOS": "CAMILITA X",
        "COMPOSICION": "CO OE 95.5%, SPX 4.5%",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 220,
          "ML": 330
        },
        "RENDIMIENTO": 3,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 26,
        "DISEÑOS": "BRUSELAS",
        "COMPOSICION": "CO OE 97%, SPX 3%",
        "ANCHO": 1.6,
        "GRS": {
          "M2": 181,
          "ML": 290
        },
        "RENDIMIENTO": 3.4,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 27,
        "DISEÑOS": "VENECIA",
        "COMPOSICION": "CO OE 95%, SPX 5%",
        "ANCHO": 1.6,
        "GRS": {
          "M2": 281,
          "ML": 450
        },
        "RENDIMIENTO": 2.2,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 28,
        "DISEÑOS": "SHIFFON MONO",
        "COMPOSICION": "CO PDO 92%, SPX 8%",
        "ANCHO": 1.7,
        "GRS": {
          "M2": 200,
          "ML": 340
        },
        "RENDIMIENTO": 2.9,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 29,
        "DISEÑOS": "LICRA LUCIA",
        "COMPOSICION": "CO 95%, SPX 5%",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 267,
          "ML": 400
        },
        "RENDIMIENTO": 2.5,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "NYLON",
        "NUEVOS": "",
        "CVO": 30,
        "DISEÑOS": "EMPERADOR",
        "COMPOSICION": "PA 91%, SPX 9%",
        "ANCHO": 1.65,
        "GRS": {
          "M2": 188,
          "ML": 310
        },
        "RENDIMIENTO": 3.2,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 31,
        "DISEÑOS": "PORTUGAL",
        "COMPOSICION": "PA 86%, SPX 14%",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 335,
          "ML": 503
        },
        "RENDIMIENTO": 2,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "VISCOSA",
        "NUEVOS": "",
        "CVO": 32,
        "DISEÑOS": "GORIZZIA",
        "COMPOSICION": "CV 94.5%, SPX 5.5%",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 190,
          "ML": 285
        },
        "RENDIMIENTO": 3.5,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 33,
        "DISEÑOS": "OTTAWA",
        "COMPOSICION": "CV 94.5%, SPX 5.5%",
        "ANCHO": 1.45,
        "GRS": {
          "M2": 280,
          "ML": 405
        },
        "RENDIMIENTO": 2.4,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "POLIÉSTER VISCOSA",
        "NUEVOS": "",
        "CVO": 34,
        "DISEÑOS": "VARSOVIA",
        "COMPOSICION": "PES 48%, CV 48%, SPX 4%",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 145,
          "ML": 218
        },
        "RENDIMIENTO": 4.6,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 35,
        "DISEÑOS": "VISKY",
        "COMPOSICION": "PES 47%, VIS  46.5% SPX 6.5%",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 190,
          "ML": 285
        },
        "RENDIMIENTO": 3.5,
        imageFolder: 'LICRAS',
        imageTag: "licras"
      }
    ]
  },
  {
    name: 'Interlocks',
    image: '../assets/images/interlock-lin.png',
    code: 'interlocks',
    designs: [
      {
        "TEJIDO": "INTERLOCKS",
        "CLASIFICACIÓN": "POLIÉSTER",
        "NUEVOS": "",
        "CVO": 36,
        "DISEÑOS": "INTER 70",
        "COMPOSICION": "PES 1.00",
        "ANCHO": 1.6,
        "GRS": {
          "M2": 150,
          "ML": 240
        },
        "RENDIMIENTO": 4.1,
        imageFolder: 'INTERLOCKS',
        imageTag: "interlocks"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 37,
        "DISEÑOS": "PREMIER PESADO",
        "COMPOSICION": "PES 1.00",
        "ANCHO": 1.6,
        "GRS": {
          "M2": 235,
          "ML": 375
        },
        "RENDIMIENTO": 2.6,
        imageFolder: 'INTERLOCKS',
        imageTag: "interlocks"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 38,
        "DISEÑOS": "PREMIER LIGERO",
        "COMPOSICION": "PES 1.00",
        "ANCHO": 1.6,
        "GRS": {
          "M2": 220,
          "ML": 350
        },
        "RENDIMIENTO": 2.8,
        imageFolder: 'INTERLOCKS',
        imageTag: "interlocks"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 39,
        "DISEÑOS": "DUBLIN",
        "COMPOSICION": "PES 1.00",
        "ANCHO": 1.6,
        "GRS": {
          "M2": 200,
          "ML": 320
        },
        "RENDIMIENTO": 3.1,
        imageFolder: 'INTERLOCKS',
        imageTag: "interlocks"
      }
    ]
  },
  {
    name: 'French terrys',
    image: '../assets/images/french-lin.png',
    code: 'french',
    designs: [
      {
        "TEJIDO": "FRENCH TERRY",
        "CLASIFICACIÓN": "POLIÉSTER ALGODÓN",
        "NUEVOS": "",
        "CVO": 40,
        "DISEÑOS": "FRENCH FIT COSMOS",
        "COMPOSICION": "PES 1%, CO 96%, SPX 3%",
        "ANCHO": 1.6,
        "GRS": {
          "M2": 220,
          "ML": 352
        },
        "RENDIMIENTO": 2.8,
        imageFolder: 'FRENCH TERRY',
        imageTag: "french_terry"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "ALGODÓN",
        "NUEVOS": "",
        "CVO": 41,
        "DISEÑOS": "FRENCH TERRY FIT",
        "COMPOSICION": "CO OE 97%, SPX 3%",
        "ANCHO": 1.6,
        "GRS": {
          "M2": 219,
          "ML": 350
        },
        "RENDIMIENTO": 2.9,
        imageFolder: 'FRENCH TERRY',
        imageTag: "french_terry"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "ACRÍLICO POLIESTER",
        "NUEVOS": "",
        "CVO": 42,
        "DISEÑOS": "ACRILICO BETA",
        "COMPOSICION": "PAN 75%, PES 21.5%, SPX 3.5%",
        "ANCHO": 1.55,
        "GRS": {
          "M2": 310,
          "ML": 480
        },
        "RENDIMIENTO": 2,
        imageFolder: 'FRENCH TERRY',
        imageTag: "french_terry"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "POLIÉSTER VISCOSA",
        "NUEVOS": "",
        "CVO": 43,
        "DISEÑOS": "FRENCH TERRY MORO",
        "COMPOSICION": "PES 96%, SPX 4%",
        "ANCHO": 1.6,
        "GRS": {
          "M2": 231,
          "ML": 369
        },
        "RENDIMIENTO": 2.7,
        imageFolder: 'FRENCH TERRY',
        imageTag: "french_terry"
      }
    ]
  },
  {
    name: 'Felpas',
    image: '../assets/images/felpa-lin.png',
    code: 'felpas',
    designs: [
      {
        "TEJIDO": "FELPAS",
        "CLASIFICACIÓN": "POLIÉSTER ALGODÓN",
        "NUEVOS": "",
        "CVO": 44,
        "DISEÑOS": "FRENCH TERRY EUREKA",
        "COMPOSICION": "PES 60%, CV 34.5%, SPX 5.5%",
        "ANCHO": 1.6,
        "GRS": {
          "M2": 270,
          "ML": 430
        },
        "RENDIMIENTO": 2.3,
        imageFolder: 'FELPA',
        imageTag: "felpa"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "POLIÉSTER",
        "NUEVOS": "",
        "CVO": 45,
        "DISEÑOS": "FELPA MUNDIALISTA",
        "COMPOSICION": "PES 1.00",
        "ANCHO": 1.6,
        "GRS": {
          "M2": 250,
          "ML": 400
        },
        "RENDIMIENTO": 2.5,
        imageFolder: 'FELPA',
        imageTag: "felpa"
      }
    ]
  },
  {
    name: 'Punto de roma',
    image: '../assets/images/punto de roma-lin.png',
    code: 'roma',
    designs: [
      {
        "TEJIDO": "PUNTO DE ROMAS",
        "CLASIFICACIÓN": "VISCOSA NYLON",
        "NUEVOS": "",
        "CVO": 77,
        "DISEÑOS": "SUPER PONTI NYLON",
        "COMPOSICION": "CV 61%, PA 33%, SPX 6%",
        "ANCHO": 1.45,
        "GRS": {
          "M2": 370,
          "ML": 535
        },
        "RENDIMIENTO": 1.8,
        imageFolder: 'PUTOS DE ROMA',
        imageTag: "punto_de_romas"
      }
    ]
  },
  {
    name: 'Piqués',
    image: '../assets/images/pique-lin.png',
    code: 'piques',
    designs: [
      {
        "TEJIDO": "PIQUÉS",
        "CLASIFICACIÓN": "POLIÉSTER ALGODÓN",
        "NUEVOS": "",
        "CVO": 46,
        "DISEÑOS": "PIQUE ITALIA 30",
        "COMPOSICION": "CO OE 51%, PES 49%",
        "ANCHO": 2,
        "GRS": {
          "M2": 175,
          "ML": 350
        },
        "RENDIMIENTO": 2.8,
        imageFolder: 'PIQUES',
        imageTag: "piques"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 47,
        "DISEÑOS": "PIQUE SICILIA",
        "COMPOSICION": "CO 50%, PES 50%",
        "ANCHO": 1.75,
        "GRS": {
          "M2": 195,
          "ML": 341
        },
        "RENDIMIENTO": 2.9,
        imageFolder: 'PIQUES',
        imageTag: "piques"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 48,
        "DISEÑOS": "PIQUE SUIZA",
        "COMPOSICION": "CO 50%, PES 50%",
        "ANCHO": 1.8,
        "GRS": {
          "M2": 267,
          "ML": 480
        },
        "RENDIMIENTO": 2.1,
        imageFolder: 'PIQUES',
        imageTag: "piques"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "POLIÉSTER",
        "NUEVOS": "",
        "CVO": 49,
        "DISEÑOS": "PIQUE WIN",
        "COMPOSICION": "PES 96%, SPX 4%",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 180,
          "ML": 270
        },
        "RENDIMIENTO": 3.7,
        imageFolder: 'PIQUES',
        imageTag: "piques"
      }
    ]
  },
  {
    name: 'Cardigans & ribs',
    image: '../assets/images/cardigan-lin.png',
    code: 'cardigans',
    designs: [
      {
        "TEJIDO": "CARDINGS & RIBS",
        "CLASIFICACIÓN": "ALGODÓN",
        "NUEVOS": "",
        "CVO": 50,
        "DISEÑOS": "D-285 CARDIGAN",
        "COMPOSICION": "CO OE 95%, SPX 5%",
        "ANCHO": 1.2,
        "GRS": {
          "M2": 275,
          "ML": 330
        },
        "RENDIMIENTO": 3,
        imageFolder: 'CARDINGS',
        imageTag: "cardings"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 51,
        "DISEÑOS": "CARDIGAN MINESSOTTA",
        "COMPOSICION": "100% ALGODÓN",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 270,
          "ML": 180
        },
        "RENDIMIENTO": 3.7,
        imageFolder: 'CARDINGS',
        imageTag: "cardings"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "POLIÉSTER ALGODÓN",
        "NUEVOS": "",
        "CVO": 52,
        "DISEÑOS": "CARDIGAN COSMOS 1X1",
        "COMPOSICION": "PES 49%, CO 48%, SPX 3%",
        "ANCHO": 1.2,
        "GRS": {
          "M2": 275,
          "ML": 330
        },
        "RENDIMIENTO": 3,
        imageFolder: 'CARDINGS',
        imageTag: "cardings"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 53,
        "DISEÑOS": "PARIS 393",
        "COMPOSICION": "PES 48.5%, CO RS 48.5%, SPX 3%",
        "ANCHO": 1.45,
        "GRS": {
          "M2": 221,
          "ML": 320
        },
        "RENDIMIENTO": 3.1,
        imageFolder: 'CARDINGS',
        imageTag: "cardings"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "POLIÉSTER",
        "NUEVOS": "",
        "CVO": 54,
        "DISEÑOS": "RIB KOREA",
        "COMPOSICION": "97% POL 3% ELAS",
        "ANCHO": 1.2,
        "GRS": {
          "M2": 290,
          "ML": 240
        },
        "RENDIMIENTO": 3.4,
        imageFolder: 'CARDINGS',
        imageTag: "cardings"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 55,
        "DISEÑOS": "RIB TOKIO",
        "COMPOSICION": "PES 99%, SPX 1%",
        "ANCHO": 1.25,
        "GRS": {
          "M2": 240,
          "ML": 300
        },
        "RENDIMIENTO": 3.3,
        imageFolder: 'CARDINGS',
        imageTag: "cardings"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "POLIÉSTER VISCOSA",
        "NUEVOS": "",
        "CVO": 56,
        "DISEÑOS": "RIB SHANGAI",
        "COMPOSICION": "PES 95%, SPX 5%",
        "ANCHO": 1.45,
        "GRS": {
          "M2": 215,
          "ML": 310
        },
        "RENDIMIENTO": 3.2,
        imageFolder: 'CARDINGS',
        imageTag: "cardings"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "ALGODÓN CDO POLIÉSTER",
        "NUEVOS": "",
        "CVO": 57,
        "DISEÑOS": "NUEVO ESCOCIA",
        "COMPOSICION": "CO CDO 47.5%, PES 48.5%, SPX 4%",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 225,
          "ML": 340
        },
        "RENDIMIENTO": 2.9,
        imageFolder: 'CARDINGS',
        imageTag: "cardings"
      },
    ]
  },
  {
    name: 'Jacquards',
    image: '../assets/images/jacq-LIN.png',
    code: 'jacquards',
    designs: [
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "POLIÉSTER ALGODÓN",
        "NUEVOS": "",
        "CVO": 58,
        "DISEÑOS": "DENIM",
        "COMPOSICION": "PES 40%, CO RS 54%, SPX 6%",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 213,
          "ML": 320
        },
        "RENDIMIENTO": 3.1,
        imageFolder: 'JACQUARDS',
        imageTag: "jacquards"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "POLIÉSTER",
        "NUEVOS": "",
        "CVO": 59,
        "DISEÑOS": "BOMBAY",
        "COMPOSICION": "PES 1.00",
        "ANCHO": 1.65,
        "GRS": {
          "M2": 103,
          "ML": 170
        },
        "RENDIMIENTO": 5.9,
        imageFolder: 'JACQUARDS',
        imageTag: "jacquards"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 60,
        "DISEÑOS": "HOLANDA",
        "COMPOSICION": "PES 1.00",
        "ANCHO": 1.6,
        "GRS": {
          "M2": 130,
          "ML": 210
        },
        "RENDIMIENTO": 4.7,
        imageFolder: 'JACQUARDS',
        imageTag: "jacquards"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 61,
        "DISEÑOS": "HAMBURGO",
        "COMPOSICION": "PES 1.00",
        "ANCHO": 1.4,
        "GRS": {
          "M2": 120,
          "ML": 170
        },
        "RENDIMIENTO": 5.8,
        imageFolder: 'JACQUARDS',
        imageTag: "jacquards"
      },
      {
        "TEJIDO": "JACQUARDS",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 62,
        "DISEÑOS": "3D LINE",
        "COMPOSICION": "PES 0.83",
        "ANCHO": 1.3,
        "GRS": {
          "M2": 490,
          "ML": 635
        },
        "RENDIMIENTO": 1.5,
        imageFolder: 'JACQUARDS',
        imageTag: "jacquards"
      },
      {
        "TEJIDO": "Y",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 63,
        "DISEÑOS": "NITRO",
        "COMPOSICION": "PES 1.00",
        "ANCHO": 1.6,
        "GRS": {
          "M2": 145,
          "ML": 230
        },
        "RENDIMIENTO": 4.3,
        imageFolder: 'JACQUARDS',
        imageTag: "jacquards"
      },
      {
        "TEJIDO": "ESPECIALIDADES",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 64,
        "DISEÑOS": "CAPRIATI HEAVY",
        "COMPOSICION": "PES 89%, SPX 11%",
        "ANCHO": 1.45,
        "GRS": {
          "M2": 159,
          "ML": 230
        },
        "RENDIMIENTO": 4.3,
        imageFolder: 'JACQUARDS',
        imageTag: "jacquards"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 65,
        "DISEÑOS": "COLA DE SIRENA",
        "COMPOSICION": "PES 89%, SPX 11%",
        "ANCHO": 1.15,
        "GRS": {
          "M2": 450,
          "ML": 520
        },
        "RENDIMIENTO": 1.9,
        imageFolder: 'JACQUARDS',
        imageTag: "jacquards"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 66,
        "DISEÑOS": "MEDELLIN",
        "COMPOSICION": "83% PES 17% SPX",
        "ANCHO": 1.15,
        "GRS": {
          "M2": 690,
          "ML": 600
        },
        "RENDIMIENTO": 1.4,
        imageFolder: 'JACQUARDS',
        imageTag: "jacquards"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 67,
        "DISEÑOS": "BOGOTA",
        "COMPOSICION": "83% PES 17% SPX",
        "ANCHO": 1.2,
        "GRS": {
          "M2": 550,
          "ML": 460
        },
        "RENDIMIENTO": 1.8,
        imageFolder: 'JACQUARDS',
        imageTag: "jacquards"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 68,
        "DISEÑOS": "MONTECARLO",
        "COMPOSICION": "83% PES 17% SPX",
        "ANCHO": 1.15,
        "GRS": {
          "M2": 520,
          "ML": 450
        },
        "RENDIMIENTO": 1.9,
        imageFolder: 'JACQUARDS',
        imageTag: "jacquards"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 69,
        "DISEÑOS": "PIRAMAR",
        "COMPOSICION": "88% PES 12% SPX",
        "ANCHO": 1.1,
        "GRS": {
          "M2": 430,
          "ML": 390
        },
        "RENDIMIENTO": 2.3,
        imageFolder: 'JACQUARDS',
        imageTag: "jacquards"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 70,
        "DISEÑOS": "CURACAO",
        "COMPOSICION": "83% PES 17% SPX",
        "ANCHO": 1.1,
        "GRS": {
          "M2": 605,
          "ML": 550
        },
        "RENDIMIENTO": 1.6,
        imageFolder: 'JACQUARDS',
        imageTag: "jacquards"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 71,
        "DISEÑOS": "RIALTO",
        "COMPOSICION": "83% PES 17% SPX",
        "ANCHO": 1.1,
        "GRS": {
          "M2": 660,
          "ML": 600
        },
        "RENDIMIENTO": 1.5,
        imageFolder: 'JACQUARDS',
        imageTag: "jacquards"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 72,
        "DISEÑOS": "MANAOS",
        "COMPOSICION": "PES 92%, SPX 8%",
        "ANCHO": 1.2,
        "GRS": {
          "M2": 325,
          "ML": 390
        },
        "RENDIMIENTO": 2.6,
        imageFolder: 'JACQUARDS',
        imageTag: "jacquards"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "POLIÉSTER VISCOSA",
        "NUEVOS": "",
        "CVO": 73,
        "DISEÑOS": "PUNTO ROMA HORTENCIA",
        "COMPOSICION": "PES 64%, CV 30%, SPX 6%",
        "ANCHO": 1.45,
        "GRS": {
          "M2": 310,
          "ML": 450
        },
        "RENDIMIENTO": 2.2,
        imageFolder: 'JACQUARDS',
        imageTag: "jacquards"
      }
    ]
  },
  {
    name: 'Doble cara',
    image: '../assets/images/doble cara-lin.png',
    code: 'doble',
    designs: [
      {
        "TEJIDO": "DOBLE CARA",
        "CLASIFICACIÓN": "POLIÉSTER",
        "NUEVOS": "",
        "CVO": 74,
        "DISEÑOS": "SUPER TECHNO",
        "COMPOSICION": "PES 88%, SPX 12%",
        "ANCHO": 1.45,
        "GRS": {
          "M2": 340,
          "ML": 495
        },
        "RENDIMIENTO": 2,
        imageFolder: 'DOBLE CARA',
        imageTag: "doblecara"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 75,
        "DISEÑOS": "TECHNO COVID SATEX",
        "COMPOSICION": "PES 91.5%, SPX 8.5%",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 215,
          "ML": 325
        },
        "RENDIMIENTO": 3,
        imageFolder: 'DOBLE CARA',
        imageTag: "doblecara"
      },
      {
        "TEJIDO": "",
        "CLASIFICACIÓN": "",
        "NUEVOS": "",
        "CVO": 76,
        "DISEÑOS": "TECHNO NOVO",
        "COMPOSICION": "PES 91.5%, SPX 8.5%",
        "ANCHO": 1.5,
        "GRS": {
          "M2": 225,
          "ML": 340
        },
        "RENDIMIENTO": 2.9,
        imageFolder: 'DOBLE CARA',
        imageTag: "doblecara"
      }
    ]
  },
];

export default categories;
